import React from 'react';
import LandingPageView2 from 'views/LandingPageView2';
import Main from 'layouts/Main';
import WithLandingPageLayout from 'WithLandingPageLayout';
import { Helmet } from 'react-helmet';

// DATA

const title = 'Save on Microsoft Azure Cloud Storage Pricing';
const content = {
  hero: {
    title: "One-stop shop for",
    emphasized: "Microsoft Azure Cloud Storage Pricing.",
    subtitle: "There is a better way to shop for Azure cloud storage.",
    titleVariant: "h2",
    subtitleVariant: "h4",      
  },
  quoteForm: {
    quote_provider:"azure",
    title: "Save 60% or more on Azure cloud storage pricing from leading partners",  
    subtitle: "Let managed service providers bid for your business",
    titleVariant: "h4",
    subtitleVariant: "h5",
    refPage: "microsoft-azure-blob-pricing", 
  },
  subText: {
    title: "Demystifying",
    emphasized: "Azure Storage Pricing.",
    subtitle: "Azure pricing can be complex. We can help you demystify Azure storage pricing and provide you with competitive quotes from Managed Service Providers which are up to 60% lower than Azure pricing. Request a quote for Azure storage today or let us help you with our Azure pricing calculator.",
    titleVariant: "h5",
    subtitleVariant: "h6",     
  }
};

// END DATA

const LandingPage = () => {
  return (
  <>
  <Helmet
    defaultTitle="WindRate | #1 Cloud Storage Pricing Engine"
    title={title}
    titleTemplate="%s | WindRate.com"
  />     
    <WithLandingPageLayout
      data={content}
      component={LandingPageView2}
      layout={Main}
    />
    </>
  )
};
 
 export default LandingPage;
 